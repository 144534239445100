import React, {useState} from 'react'
import {Button, Grid, Space, Avatar, TextArea } from 'antd-mobile'
// import { Slider } from 'antd';
// import logo from './../logo.svg';
import './Radio.css';
import logo from "./walkie-talkie.png"
import BluetoothTerminal from "bluetooth-terminal"
// import MicrophoneStream from "microphone-stream";
import AudioAnalyser from './AudioAnalyser';
// import * as waveResampler from "wave-resampler";
// import * as ffmpeg from "@ffmpeg/ffmpeg";

// import OpusToPCM from 'opus-to-pcm';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
// import { Typography } from '@mui/material';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import { useTheme } from '@mui/material/styles';
import {AACCodec, AACCodecModule, getWasm} from "aac-stream-wasm";
import { resolveModuleNameFromCache } from 'typescript';
import Module from 'module';

var aacModule:AACCodecModule;
var aacCodec: AACCodec;//AACCodecModule.AACCodec;
getWasm().then((modul: AACCodecModule) => {
  aacModule = modul;
  aacCodec = new aacModule.AACCodec("Wasm");
});





function valuetext(value: number) {
  return `${value}°C`;
}
var frameCntr = 0;
const marks = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 0,
    label: '0',
  },
  {
    value: -3,
    label: '-3',
  },
  {
    value: -10,
    label: '-10',
  },
  {
    value: -15,
    label: '-15',
  },
  {
    value: -20,
    label: '-20',
  },
];

// getWasm().then((module) => {
//   console.log('The result is: ', module.add(3, 4));
// });

// Module.print = console.log;
// const wasmReady = new Promise(resolve => {
//   Module['onRuntimeInitialized'] = () => {
//     console.log("wasm ready!");
//     resolve();
//   };
// });


let terminal = new BluetoothTerminal();

var outBuffer = new Uint8Array([]);


var biquadFilter1: BiquadFilterNode;//= audioContext.createBiquadFilter();
var biquadFilter2: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter3: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter4: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter5: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter6: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter7: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter8: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter9: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter10: BiquadFilterNode;// = audioContext.createBiquadFilter();

var audioContext: AudioContext;

var downsampleBuffer = function (buffer: ArrayLike<number>, sampleRate: number, outSampleRate: number) {
  if (outSampleRate == sampleRate) {
      return buffer;
  }
  if (outSampleRate > sampleRate) {
      throw "downsampling rate show be smaller than original sample rate";
  }
  var sampleRateRatio = sampleRate / outSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Int16Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;
  while (offsetResult < result.length) {
      var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      var accum = 0, count = 0;
      for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
          accum += buffer[i];
          count++;
      }

      result[offsetResult] = Math.min(1, accum / count)*0x7FFF;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
  }
  return result.buffer;
}

// let outData = [];
var cnttt = 0;
function Radio() {
    const [logText, setLogText] = useState<string>("");
    const [connectText, setConnectText] = useState<string>("Connect");
    const [micText, setMicText] = useState<string>("Send audio");
    const [audio, setAudio] = useState<MediaStream>();
    const [biquadFilter30, setBiquadFilter30] = useState<number>(0);
    const [biquadFilter60, setBiquadFilter60] = useState<number>(0);
    const [biquadFilter120, setBiquadFilter120] = useState<number>(0);
    const [biquadFilter250, setBiquadFilter250] = useState<number>(0);
    const [biquadFilter500, setBiquadFilter500] = useState<number>(0);
    const [biquadFilter1000, setBiquadFilter1000] = useState<number>(0);
    const [biquadFilter2000, setBiquadFilter2000] = useState<number>(0);
    const [biquadFilter4000, setBiquadFilter4000] = useState<number>(0);
    const [biquadFilter8000, setBiquadFilter8000] = useState<number>(0);
    const [biquadFilter16000, setBiquadFilter16000] = useState<number>(0);

    const theme = useTheme();

    // var wsClient = new WebSocket("wss://marcin.bialystok.pl:1221");//process.env.REACT_APP_WEBSOCKET_SERVER ?? "ws://localhost:1221");

    // wsClient.onopen = (_event) => {
    //   wsClient.send("Hello I'm WebPMR");
    // };

    // wsClient.onmessage = (event) => {
    //     //tu leca ramki
    //   console.log("Server data: ", JSON.stringify(event));
    // };

    const connect = async () => {
        terminal.receive = function(data: any) {
            setLogText(data);
        };
        setConnectText("Connecting...")
        try {
            terminal.connect().then(() => {
                setConnectText("Disconnect");
            });
        } catch (e) {
            setLogText(() => ((e as any).message));
            setConnectText("Connect");
        }
    }

    const disconnect = async () => {
        setConnectText("Connect");
        try {
            terminal.disconnect();
        } catch (e) {

        }
        try {
            terminal = new BluetoothTerminal();
        } catch (e) {

        }
        // terminal.send('Simon says: Hello, world!');
    }


    const sendAdudio = async () => {
        
    };


    

    var appendBuffer = function(buffer1: Uint8Array, buffer2: ArrayBuffer) {
      //const input = buffer2 as Uint16Array;
      //const resampl: ArrayLike<number> = waveResampler.resample(buffer1, 48000, 16000) as ArrayLike<number>;
      // const resampl = new Uint8Array(downsampleBuffer(buffer1, 16000, 16000));
      // const resampl = buffer1;
      const resampled = buffer1;//Uint8Array.from(resampl);
      var tmp = new Uint8Array(resampled.byteLength + buffer2.byteLength);
      tmp.set(new Uint8Array(resampled), 0);
      tmp.set(new Uint8Array(buffer2), resampled.byteLength);
      return tmp;
    };

    const getMicrophone = async () => {
      setMicText(() => "Stop");
      outBuffer = new Uint8Array([]);
        const audio = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });

        audioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        // const audioContext = new (window.AudioContext)();
        const analyzer = audioContext.createAnalyser();
        // const dataArr = new Uint8Array(analyzer.frequencyBinCount);
        const dataArr = new Float32Array(analyzer.frequencyBinCount);
        var source = audioContext.createMediaStreamSource(audio);

// var somm = audioContext.createMediaStreamDestination();
// var somm2 = audioContext.createMediaStreamDestination();
// audioContext.sampleRate
await audioContext.audioWorklet.addModule('https://marcin.pages.dev/worklets/processor.js')
// .then(() => {
//   let node = new AudioWorkletNode(audioContext, 'port-processor');
//   node.port.onmessage = (event) => {
//     // Handling data from the processor.
//     console.log(event.data);
//   };

//   node.port.postMessage('Hello!');
// }).catch((err) => console.log("jaki grzyb???? ", err.message));

const bypasser = new AudioWorkletNode(audioContext, 'port-processor');
bypasser.port.postMessage({
  command: "init",
  data: {
    sampleRate: audioContext.sampleRate,
  }
});



bypasser.port.onmessage = (event) => {

  // for (let i = 0; i < 100000; i++) {
  //   for (let j = 0; j < 10000; j++) {

  //   }
  // }
  frameCntr++;
  if (frameCntr % 510 === 0) {
    console.log("Frame: ", ++frameCntr, Array.from(event.data as Int32Array ));
    frameCntr = 0;
  }
};
var mute = false;
// let cnttt = 0;
// const cnt = () => {
// // cnttt++;
// console.log(cnttt);
// }
// setInterval(() => {
//   cnt();
//   if (cnttt = 1) {
//     bypasser.port.postMessage({
//       command: "mute",
//       data: {
//         mute: false,
//       }
//     })
//   }
  
//   if (cnttt === 4) {
//     mute = true;
//     cnttt = 0;
//     bypasser.port.postMessage({
//       command: "mute",
//       data: {
//         mute: true,
//       }
//     })
//   }
//   console.log("ddddddd", cnttt++, cnt())

// }, 1000);
// var somm = audioContext.createScriptProcessor();
var somm = audioContext.createMediaStreamDestination();
// somm.addEventListener('audioprocess', (e) => {
//   console.log("cos jest",e);
//   // const rawLeftChannelData = somm.getChannelData(0);
//   // rawLeftChannelData is now a typed array with floating point samples
// });

  biquadFilter1= audioContext.createBiquadFilter();
  biquadFilter2 = audioContext.createBiquadFilter();
  biquadFilter3 = audioContext.createBiquadFilter();
  biquadFilter4 = audioContext.createBiquadFilter();
  biquadFilter5 = audioContext.createBiquadFilter();
  biquadFilter6 = audioContext.createBiquadFilter();
  biquadFilter7 = audioContext.createBiquadFilter();
  biquadFilter8 = audioContext.createBiquadFilter();
  biquadFilter9 = audioContext.createBiquadFilter();
  biquadFilter10 = audioContext.createBiquadFilter();


  const filerType: BiquadFilterType = "peaking";
  biquadFilter1.type = filerType;
  biquadFilter1.frequency.value = 30;
  biquadFilter1.gain.value = biquadFilter30;
  biquadFilter1.Q.value = 0.1;
  biquadFilter1.connect(biquadFilter2);

  source.connect(biquadFilter1);


  biquadFilter2.type = filerType;
  biquadFilter2.frequency.value = 60;
  biquadFilter2.gain.value = biquadFilter60;
  biquadFilter2.Q.value = 0.1;
  biquadFilter2.connect(biquadFilter3);

  biquadFilter3.type =filerType;
  biquadFilter3.frequency.value = 120;
  biquadFilter3.gain.value = biquadFilter120;
  biquadFilter3.Q.value = 0.1;
  biquadFilter3.connect(biquadFilter4);

  biquadFilter4.type = filerType;
  biquadFilter4.frequency.value = 250;
  biquadFilter4.gain.value = biquadFilter250;
  biquadFilter4.Q.value = 0.1;
  biquadFilter4.connect(biquadFilter5);

  biquadFilter5.type = filerType;
  biquadFilter5.frequency.value = 500;
  biquadFilter5.gain.value = biquadFilter500;
  biquadFilter5.Q.value = 0.1;
  // biquadFilter6.Q.value = 0.25;
  biquadFilter5.connect(biquadFilter6);

  biquadFilter6.type = filerType;
  biquadFilter6.frequency.value = 1000;
  biquadFilter6.gain.value = biquadFilter1000;
  biquadFilter6.Q.value = 0.1;
  // biquadFilter6.Q.value = 0.5;
  biquadFilter6.connect(biquadFilter7);

  biquadFilter7.type = filerType;
  biquadFilter7.frequency.value = 2000;
  console.log(biquadFilter7.gain.minValue, biquadFilter7.gain.maxValue);
  biquadFilter7.gain.value = biquadFilter2000;
  biquadFilter7.Q.value = 0.1;
  biquadFilter7.connect(biquadFilter8);

  biquadFilter8.type = filerType;
  biquadFilter8.frequency.value = 4000;
  biquadFilter8.gain.value = biquadFilter4000;
  biquadFilter8.Q.value = 0.1;
  biquadFilter8.connect(biquadFilter9);

  biquadFilter9.type =filerType;
  biquadFilter9.frequency.value = 8000;
  biquadFilter9.gain.value = biquadFilter8000;
  biquadFilter9.Q.value = 0.1;
  // biquadFilter9.connect(biquadFilter10);

  // biquadFilter10.type = filerType;
  // biquadFilter10.frequency.value = 16000;
  // biquadFilter10.gain.value = biquadFilter16000;



  // biquadFilter.connect(somm);
  //tu podpinamy do wyjscia
  // biquadFilter9.connect(audioContext.destination)
  biquadFilter9.connect(bypasser);
  bypasser.connect(audioContext.destination);
// source.connect(audioContext.destination);

        

      //   const decoder = new OpusToPCM({
      //     channels: 1
      // });

    //   decoder.on('decode', (pcmData: any)=> {
    //     console.log(pcmData); /* PCM data */
    // }); 
        var options: MediaRecorderOptions = {
            audioBitsPerSecond : 48000,
            bitsPerSecond: 48000,
            // videoBitsPerSecond : 2500000,
            mimeType : 'audio/webm;codecs="pcm"'
          }
          // analyzer
          //if(!MediaRecorder.isTypeSupported(options['mimeType'])) 
          // options['mimeType'] =  "audio/webm\;codecs:aac";
          const voice = new MediaRecorder(audio, options);
          voice.start(20);
          voice.ondataavailable = async function(data){

            analyzer.getFloatTimeDomainData(dataArr);
            var output = new Uint8Array(dataArr.buffer);

           // outBuffer = appendBuffer(outBuffer, await data.data.arrayBuffer());
            biquadFilter5.gain.value = biquadFilter500;
            biquadFilter6.gain.value = biquadFilter1000;
            biquadFilter7.gain.value = biquadFilter2000;
          };
      
          voice.onstop = function(){
            console.log('stop audio call');
          }
        // const source = audioContext.createMediaStreamSource(audio);
        // var options = {mimeType: 'audio/webm;codecs=pcm'};
// const mediaRecorder = new MediaRecorder(audio, options);
// (mediaRecorder as any).ondataavailable((ev: any) => {

// })
        //source.context.decodeAudioData()
        setAudio( () => audio );
      }
    
    const  stopMicrophone= () => {
      setMicText(() => "Send audio");
        console.log(Array.from(outBuffer));
        audio!.getTracks().forEach(track => track.stop());
        setAudio( () => null as any );

        audioContext.close();
        frameCntr = 0;
      }
    
    const  toggleMicrophone= () => {
        if (audio) {
          stopMicrophone();
        } else {
          getMicrophone();
        }
      }

      const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

    // const module = new Greeter("elo");

// withGreeterScope()
    // withGreeter(greeterModule => {
    //   // construct a new C++ `Greeter` instance
    //   const greeter = new greeterModule.Greeter("Wasm");
    
    //   // call a member function
    //   console.log(greeter.greet(greeterModule.LanguageCode.EN));
      
    //   // any created C++ objects will be destroyed after the function exits, unless they are persisted
    // });

    // const greeter = new greeterModule.Greeter("Wasm");
    const test = async() => {
      // greeterModule.greet
      console.log(aacCodec.greet(aacModule.LanguageCode.EN));
      console.log(aacCodec.aacenc_init(2, 1, 24000, 56000));
      var input_ptr  = (aacModule as any)._malloc(5  * 4 );
      const array = new Int32Array(5);
      var memory = new WebAssembly.Memory({initial:10, maximum:100, shared: true});

      // let myArrayPtr = accMod.allocateF32Array(5);
      // let myArray = new Int32Array(memory.buffer);
      // let myArray = (aacModule as any).getArray(Float32Array, (aacModule as any).allocate(5 * 4));
      let myArray = new Int32Array(input_ptr, 5*4);

      myArray.set([1, 2, 3, 5, 7]);
      // array.set([1, 2, 3, 5, 7]);

      const arr = [1,2,3,4,5];

      
      // aacCodec.instance.export
      console.log(aacCodec.sumArrayInt32(myArray, 5));
      // console.log(await addNumbers(1, 10));
    }
    return (
        <div className="demoBlock">
            <Grid columns={2}>
                <Grid.Item>
                    <Space block wrap>
                        <Avatar src={logo} />
                    <div className='title grid-demo-item-block'>WebPMR</div>
                    </Space>
                </Grid.Item>
                <Grid.Item>

                    <div className='title grid-demo-item-block right'>2022 SP4MK</div>
                </Grid.Item>
            </Grid>
            <div className="main">
                <Space>
                    <Button color='primary' onClick={() => connectText === "Connect" ? connect() : disconnect()}>{connectText}</Button>
                    <Button color='danger' onClick={() => micText === "Send audio" ? getMicrophone() : stopMicrophone()}>{micText}</Button>
                    <Button color='success' onClick={() => test()}><div id="file">Init audio codec</div></Button>
                </Space>
                
            </div>
            <div className="visual-canvas">
            {audio ? <AudioAnalyser className="visual-canvas" audio={audio} /> : ''}
            </div>
            <div className="visual-canvas"><br></br></div>
            {/* <Slider></Slider> */}
            <Stack sx={{ height: 300 }} spacing={4} direction="row">
            {/* <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        value={biquadFilter30}
        onChange={(_, value) => setBiquadFilter30(value as number)}
      />
                  <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        valueLabelDisplay="auto"
        value={biquadFilter60}
        onChange={(_, value) => setBiquadFilter60(value as number)}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        valueLabelDisplay="auto"
        value={biquadFilter120}
        onChange={(_, value) => setBiquadFilter120(value as number)}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        valueLabelDisplay="auto"
        value={biquadFilter250}
        onChange={(_, value) => setBiquadFilter250(value as number)}
      /> */}
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        valueLabelDisplay="auto"
        value={biquadFilter500}
        onChange={(_, value) => {
          setBiquadFilter500(value as number);
          biquadFilter5.gain.setValueAtTime(value as number, audioContext.currentTime+1);
        }}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        value={biquadFilter1000}
        onChange={(_, value) => {
          setBiquadFilter1000(value as number);
          biquadFilter6.gain.setValueAtTime(value as number, audioContext.currentTime+1);
        }}
        marks={marks}
        title={"dd"}
        valueLabelDisplay="auto"
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        value={biquadFilter2000}
        onChange={(_, value) => {
          setBiquadFilter2000(value as number);
          biquadFilter7.gain.setValueAtTime(value as number, audioContext.currentTime+1);
        }}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        value={biquadFilter4000}
        onChange={(_, value) => {
          setBiquadFilter4000(value as number);
          biquadFilter8.gain.setValueAtTime(value as number, audioContext.currentTime+1);
        }}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        value={biquadFilter8000}
        onChange={(_, value) => {
          setBiquadFilter8000(value as number);
          biquadFilter9.gain.setValueAtTime(value as number, audioContext.currentTime+1);
        }}
      />
                        <Slider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={0}
        min={-50}
        max={50}
        marks={marks}
        title={"dd"}
        value={biquadFilter16000}
        onChange={(_, value) => setBiquadFilter16000(value as number)}
      />
      </Stack>
      <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
          <VolumeDownRounded htmlColor={lightIconColor} />
          <Slider
            aria-label="Volume"
            defaultValue={30}
            sx={{
              color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
              '& .MuiSlider-track': {
                border: 'none',
              },
              '& .MuiSlider-thumb': {
                width: 24,
                height: 24,
                backgroundColor: '#fff',
                '&:before': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <VolumeUpRounded htmlColor={lightIconColor} />
        </Stack>

            <Space wrap={false} block={false} justify="stretch">
                <TextArea
                    placeholder=''
                    value={logText}
                    // onChange={val => {
                    //     setValue(val)
                    // }}
                />
                
            </Space>
            
        </div>
    );
}

export default Radio;


